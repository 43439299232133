.content {
	position: absolute;
	margin: auto;
	padding: 4em;
	left: 0;
    right: 0;
	bottom: 0;
	max-height: 100%;
	max-width: 65vh;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

@media (orientation: landscape) {
	.content {
		position: absolute;
		width: 100%;
		max-width: 65vh;
		max-height: 100%;
		height: 100%;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
