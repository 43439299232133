@font-face {
	font-family: "Outfit";
	src: url(./assets/fonts/Outfit-VariableFont_wght.ttf);
	font-smooth: always;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}

@font-face {
	font-family: "Outfit-Regular";
	src: url(./assets/fonts/Outfit-Regular.ttf);
	font-smooth: always;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}

@font-face {
	font-family: "Outfit-SemiBold";
	src: url(./assets/fonts/Outfit-SemiBold.ttf);
	font-smooth: always;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}

body {
	font-size: 1vh;
	font-family: "Outfit-Regular", sans-serif;
	text-align: center;
	background: #ffffff;
	background-attachment: fixed;
	padding: 0;
	margin: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	touch-action: manipulation;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}
