.price {
	text-align: center;
	width: 100%;
	margin: 0 auto 4vh;
}

.value {
	font-size: 7em;
	color: #224466;
	font-family: "Outfit-SemiBold";
	margin: 5vh auto 2.5vh;
	user-select: none;
}

.currency {
	width: fit-content;
	padding: 0 1.5em;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	color: #224466;
	height: 4vh;
	margin: 0 auto;
	-webkit-border-radius: 2.5em;
	-moz-border-radius: 2.5em;
	border-radius: 2.5em;
	font-size: 2.5em;
	color: #224466;
	background: #f0f0f0;
	font-family: "Outfit-Regular";
	user-select: none;
}

.keypad {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(4, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.key-hitbox {
	aspect-ratio: 4 / 3;
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	margin: auto;
	user-select: none;
}

.key-visual {
	aspect-ratio: 4 / 3;
	width: 96%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	margin: auto;
	font-size: 4em;
	color: #224466;
	background: none;
	-webkit-border-radius: 5em;
	-moz-border-radius: 5em;
	border-radius: 5em;
	font-family: "Outfit-Regular";
	transition: 0.5s;
	user-select: none;
}

.key-visual:active {
	aspect-ratio: 4 / 3;
	width: 96%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	margin: auto;
	font-size: 4em;
	color: #224466;
	background: #f0f0f0;
	-webkit-border-radius: 5em;
	-moz-border-radius: 5em;
	border-radius: 5em;
	font-family: "Outfit-Regular";
	transition: 0s;
	user-select: none;
}

.svg-add {
	height: 1em;
}

.svg-delete {
	height: .85em;
}

.submit {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	margin: 4vh 0 4vh;
	width: 100%;
	height: 10vh;
	font-size: 5em;
	-webkit-border-radius: 5em;
	-moz-border-radius: 5em;
	border-radius: 5em;
	font-size: 5em;
	color: #ffffff;
	background: #40c277;
	font-family: "Outfit-Regular";
	user-select: none;
}

.submit:active {
	background: #36A766;
}

.submit[aria-disabled=true]
{
  pointer-events: none;
  background: #f0f0f0;
  color: #bbbbbb;
}
